import React, { useState, useEffect, useContext } from 'react'
import DashboardWrapper from '../../../components/BestSchoolsAwards/DashboardWrapper/DashboardWrapperTide'
import * as DashboardStyles from './Dashboard.module.scss'
import { Helmet } from 'react-helmet'
import { LoginContext } from '../../../lib/utils'
import getEntries from '../../../api/women-in-business/get-entries'
import { FormFields } from '../../../components/BestSchoolsAwards/Form/TideForm'
import { useMutation } from '@apollo/client'
import { SaveWomenInBusiness } from '../../../graphql/queries/SaveWomenInBusiness'
import { navigate } from 'gatsby'
import { moveTideSubmission } from '../../../api/best-school-awards/dropbox'

const mandatory = [
  'name',
  'business_name',
  'business_address',
  'entryId',
  'businessType',
  'contact_number',
  'have_uploaded_pdf',
  'email'
]

const mandatoryLabels = [
  'Name',
  'Business Name',
  'Business Address',
  'Entry ID',
  'Business Type',
  'Contact Number',
  'Have Uploaded PDF',
  'Email'
]

const WomenInBusinessEnter = () => {
  const [email, setEmail] = useState<string>()
  const [wrongLogin, setWrongLogin] = useState<boolean>(false)
  const [foundEntry, setFoundEntry] = useState<FormFields>()
  const { usr, loading: usrLoading } = useContext(LoginContext)
  const [entryValid, setEntryValid] = useState<boolean>(false)
  const [errorFields, setErrorFields] = useState<string[]>([])
  const [entrySubmitted, setEntrySubmitted] = useState<boolean>(false)
  const [saveWomenInBusiness, { data: saveData, loading }] =
    useMutation(SaveWomenInBusiness)

  useEffect(() => {
    if (typeof window !== undefined) {
      // eslint-disable-next-line node/no-unsupported-features/node-builtins
      const emailAddr = new URLSearchParams(window.location?.search).get(
        'email'
      )
      if (emailAddr) setEmail(emailAddr)
    }
  }, [])

  const checkForEntries = async (emailAddr: string) => {
    try {
      await getEntries(emailAddr)
        .then(res => res.data)
        .then(data => {
          console.log(data)
          setFoundEntry(data)
        })
    } catch (e: any) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (usr?.viewer?.email) {
      if (email) {
        if (usr.viewer.email.toLowerCase() === email.toLowerCase()) {
          checkForEntries(usr.viewer.email)
        } else {
          setWrongLogin(true)
        }
      }
    } else {
      if (!usrLoading && email) {
        navigate(
          `/women-in-business/dashboard/login/?redirect=/women-in-business/dashboard/complete/?email=${email}`
        )
      }
    }
  }, [usr, email])

  useEffect(() => {
    if (saveData && saveData.saveWomenInBusiness?.success) {
      setEntrySubmitted(true)
    }
  }, [saveData])

  useEffect(() => {
    if (entrySubmitted) {
      navigate('/women-in-business/thank-you/')
    }
  }, [entrySubmitted])

  useEffect(() => {
    if (foundEntry) {
      if (foundEntry.have_confirmed_tide_account) {
        setEntrySubmitted(true)
      }

      const invalidFields: string[] = []

      mandatory.forEach(field => {
        if (
          foundEntry[field] &&
          foundEntry[field] !== null &&
          foundEntry[field] !== ''
        ) {
          // Do nothing
        } else {
          invalidFields.push(field)
        }
      })

      if (invalidFields.length > 0) {
        setEntryValid(false)
        setErrorFields(invalidFields)
      } else {
        setEntryValid(true)
      }
    }
  }, [foundEntry])

  const fixDropboxUrl = async () => {
    if (foundEntry) {
      const businessName = (foundEntry.business_name as string)
        .replace(/[/\\?%*:|"<>]/g, '-') // Remove invalid characters
        .replace(/[\s]+/g, '_') // Replace spaces with underscores
        .toLowerCase()
      const legacyBusinessName = (foundEntry.business_name as string).replace(
        ' ',
        ''
      )
      await moveTideSubmission(
        `${foundEntry.businessType}/${businessName}_${foundEntry.contact_number}.pdf`,
        `${foundEntry.businessType}/${legacyBusinessName}_${foundEntry.contact_number}.pdf`
      )
    }
  }

  const finaliseEntry = () => {
    if (foundEntry) {
      const newData = {
        ...foundEntry,
        have_confirmed_tide_account: true
      }

      fixDropboxUrl()

      saveWomenInBusiness({
        variables: {
          payload: newData
        }
      })
    }
  }

  return (
    <>
      <Helmet>
        <title>She Means Business | Muddy Stilettos</title>
      </Helmet>
      <DashboardWrapper>
        <div className={DashboardStyles.Intro}>
          <h1>Step 5 - Complete your entry</h1>
          {wrongLogin ? (
            <>
              <p>
                <strong>Oops!</strong>
              </p>
              <p>
                It looks as though you&apos;re logged in as{' '}
                <em>{usr?.viewer?.email}</em>, but you are trying to complete
                the submission for <em>{email}</em>.
              </p>
              <p>
                We can&apos;t check the status of applications where the email
                addresses don&apos;t match, so please login with the correct
                account to complete your entry.
              </p>
              <p>
                <a
                  href={`/women-in-business/logout/`}
                  className={DashboardStyles.Button}
                >
                  Log out
                </a>
                {` `}
                <a
                  href={`/women-in-business/login/?redirect=/women-in-business/dashboard/complete/?email=${email}`}
                  className={DashboardStyles.Button}
                >
                  Log in
                </a>
              </p>
            </>
          ) : (
            <>
              <p>
                <strong>THIS COMPETITION IS NOW CLOSED FOR NEW ENTRIES.</strong>
              </p>

              <p>
                For further information or queries, please contact{` `}
                <a href="mailto:hq@muddystilettos.co.uk">
                  hq@muddystilettos.co.uk
                </a>
                .
              </p>
              {foundEntry ? (
                <div className={DashboardStyles.EntryWrapper}>
                  <div className={DashboardStyles.EntryRow1}>
                    <p
                      className={DashboardStyles.BusinessName}
                      dangerouslySetInnerHTML={{
                        __html: foundEntry.business_name as string
                      }}
                    />
                  </div>
                  <div className={DashboardStyles.EntryRow2}>
                    <p>
                      <strong>Business Name</strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: foundEntry.business_name as string
                        }}
                      />
                    </p>
                    <p>
                      <strong>Name</strong>
                      <span>{foundEntry.name}</span>
                    </p>
                    <p className={DashboardStyles.Capitalize}>
                      <strong>Business Type </strong>
                      <span>
                        {foundEntry.businessType
                          ? (foundEntry.businessType as string)?.replace(
                              '-',
                              ' '
                            )
                          : '❌'}
                      </span>
                    </p>
                    <p>
                      <strong>Business Address </strong>
                      <span>{foundEntry.business_address}</span>
                    </p>
                    <p>
                      <strong>Contact Number </strong>
                      <span>{foundEntry.contact_number}</span>
                    </p>
                    <p>
                      <strong>Email </strong>
                      <span>{foundEntry.email}</span>
                    </p>
                    <p>
                      <strong>Website URL</strong>
                      <span>{foundEntry.website_url ?? 'n/a'}</span>
                    </p>
                    <p>
                      <strong>PDF Submitted</strong>
                      <span>{foundEntry.have_uploaded_pdf ? '✅' : '❌'} </span>
                    </p>
                    <p>
                      <strong>Tide Account Created & Connected</strong>
                      <span>{foundEntry.have_uploaded_pdf ? '✅' : '❌'} </span>
                    </p>
                  </div>
                  {entryValid ? (
                    <div className={DashboardStyles.Finalise}>
                      <button
                        onClick={finaliseEntry}
                        className={DashboardStyles.Button}
                      >
                        {!loading ? 'Finalise Entry' : 'Submitting...'}
                      </button>
                    </div>
                  ) : (
                    <>
                      <p>The following fields are not valid:</p>
                      {errorFields.length > 0 &&
                        errorFields.map(field => {
                          return (
                            <p key={field}>
                              {mandatoryLabels[mandatory.indexOf(field)]}
                            </p>
                          )
                        })}

                      <p>
                        Please go back to the application using the button below
                        to rectify the fields, and then click the link in your
                        email once again.
                      </p>

                      <a
                        href={`/women-in-business/dashboard/enter`}
                        className={DashboardStyles.Button}
                      >
                        Edit Entry
                      </a>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <p>Hi, {email}.</p>
                  <p>
                    It doesn&apos;t look like we were able to find an entry that
                    matches your email address.
                  </p>
                  <p>
                    Make sure you fill out the details on our{' '}
                    <a href={`/women-in-business/dashboard/`}>Get Started</a>{' '}
                    page, and then once it prompts you to create your Tide
                    account, click on the link you received in your email once
                    again.
                  </p>
                  <p>Many thanks,</p>
                  <p>The Muddy Team</p>
                </>
              )}
            </>
          )}
        </div>
      </DashboardWrapper>
    </>
  )
}

export default WomenInBusinessEnter
